import TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';
import Experience from "../Experience.js";
import Portal from './Portal.js';
import Raycast from '../Utils/Raycast.js';

export default class Floor{
    constructor(name, position, resource){
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.time = this.experience.time;

        this.resource = resource;
        this.introAnimationStarted = false;
        this.isRed = false;
        this.toBlink = true;
        this.setModel(name, position);
        this.raycast = new Raycast();
        console.log(this.raycast);
        document.body.addEventListener("mousemove", (event)=>{
            this.raycast.clickHandler(event);
        }, false)
        document.body.addEventListener("click", ()=>{
            if(!this.toBlink){
                document.body.querySelector("#parent").style.display = "";
            }
        });
        this.raycast.on("mousemove", (args)=>{
            if(args.includes("Path_0")){
                this.toBlink = false;
                //this.interactiveShelve.material.map = this.experience.resources.items.shelveColorRedMap;
            }else{
                this.toBlink = true;
            }
        })
        //this.setPortal();
    }
    setModel(name, position){
        this.model = this.resource.scene;
        this.model.name = name;
        this.model.scale.set(1,1,1);
        this.model.position.set(position.x, position.y, position.z);
        
        this.scene.add(this.model);
        //console.log(this.experience.resources.items.logoModel)
        this.logoModel = this.experience.resources.items.logoModel.scene;
        this.logoModel.position.set(10.057,11.542,29.797);
        this.logoModel.scale.set(20,7,20);
        this.logoModel.rotation.set(-Math.PI/2, Math.PI, 0);
        this.model.add(this.logoModel);
        this.model.traverse((child) =>{
            if(child.isMesh){
                if (child.material.envMap) {
                    child.material.envMap = this.experience.resources.items.roomEnvMap;
                }
                //child.material.envMap = this.experience.resources.items.roomEnvMap;
                //console.log(this.experience.resources.items.roomEnvMap);
                if(child.name == "Plane003"){
                    this.experience.world.ClickContorols.objects.push(child);
                    child.material.color.set(0x444444);
                }else if(child.children.length == 0 && !(["Cube117", "Circle004", "Plane017", "Plane016", "Plane014", "Circle138","Circle440", "Circle124", "Circle107", "Circle394", "Circle397"].indexOf(child.name) > -1)){
                    this.experience.world.ClickContorols.calculateCollisionPoints(child, 1, 'collision', false);
                }
                if(child.material.name == "Glass white"){
                    let prevMat = child.material;
                    child.material = new THREE.MeshDepthMaterial(prevMat);
                    child.material.opacity = 0.5;
                }
                if(child.material.name == "mannequin"){
                    child.material.map.magFilter = THREE.LinearFilter;
                    child.material.map.minFilter = THREE.NearestMipmapNearestFilter;
                }
                if(["Plane017", "Plane016", "Plane014"].indexOf(child.name) > -1){
                    child.visible = false;
                }
                child.castShadow = true;
                if(child.material.map){
                    child.material.map.anisotropy = 16;
                    child.material.map.minFilter = THREE.LinearFilter;
                    //child.material.map.encoding = THREE.sRGBEncoding;
                }
                if(child.material.name == "Glass"){
                    child.frustumCulled = false;
                    child.material.opacity = 0.4;
                }
                if(child.material.name == "Glass.003"){
                    child.material.blending = THREE.AdditiveBlending;
                    child.material.opacity = 0.6
                }
                if(child.material.name == "Glass.002"){
                    child.material.blending = THREE.AdditiveBlending;
                    child.material.opacity = 0.7
                }
                if(child.material.map){
                    child.material.map.minFilter = THREE.LinearFilter;
                }
                if(child.name == "Handle_Front001"){
                    this.interactiveShelve = child;
                    this.interactiveShelveBlink();
                }
            }
        })
        this.introAnimation();
        this.experience.canvas.addEventListener("touchend", ()=>{
            if(!this.introAnimationStarted){
                this.introAnimation();
            }
        }, false)    
        
    }
    setPortal(){
        this.portal = new Portal("Portal"+this.model.name, this.model.position, new THREE.Vector3(
            Math.floor(Math.random() * (3 + 3 + 1) -3),
            0.2,
            Math.floor(Math.random() * (-3 + 5.8 + 1) -5.8)
        ));
    }
    introAnimation(){
        this.introAnimationStarted = true;
        let initalCameraPosition = new THREE.Vector3(this.experience.camera.instance.position.x, this.experience.camera.instance.position.y, this.experience.camera.instance.position.z);
        let finalCameraPosition = new THREE.Vector3(0, 6, 7);
        new TWEEN.Tween(initalCameraPosition)
                .to(finalCameraPosition, 2000)
                .easing(TWEEN.Easing.Cubic.Out)
                .onUpdate(()=>{
                    this.experience.camera.instance.position.set(initalCameraPosition.x, initalCameraPosition.y, initalCameraPosition.z);
                })
                .onComplete(()=>{
                    this.experience.world.attachControls();
                })
                .start();
    }
    interactiveShelveBlink(){
        /*if(this.toBlink){
            if(this.isRed){
                this.interactiveShelve.material.map = this.experience.resources.items.shelveColorRedMap;
            }else{
                this.interactiveShelve.material.map = this.experience.resources.items.shelveColorNormalMap;
            }
            this.interactiveShelve.material.map.flipY = false;            
        }  
        setTimeout(()=>{
            this.isRed = !this.isRed;
            this.interactiveShelveBlink();
        }, 800);*/      
    }
    destroy(){
        this.scene.remove(this.model);
    }
    update(){
        TWEEN.update();
    }
}