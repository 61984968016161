export default [
    /*{
        name: 'environmentMapTexture',
        type: 'cubeTexture',
        path:
        [
            'textures/environmentMap/px.png',
            'textures/environmentMap/nx.png',
            'textures/environmentMap/py.png',
            'textures/environmentMap/ny.png',
            'textures/environmentMap/pz.png',
            'textures/environmentMap/nz.png'
        ]
    },*/
    {
        name: 'roomModel',
        type: "gltfModel",
        //path: "https://bbcdn.githack.com/niplusplus/showroom/raw/master/static/models/Room/room11.glb?min=1"
        path: "https://bbcdn.githack.com/niplusplus/showroom-final/raw/master/static/models/Room/output.glb?min=1"
    },
    {
        name: 'logoModel',
        type: "gltfModel",
        //path: "https://bbcdn.githack.com/niplusplus/showroom/raw/master/static/models/Room/room11.glb?min=1"
        path: "https://bbcdn.githack.com/niplusplus/showroom-final/raw/master/static/models/Room/toppotaco.glb?min=1"
    },
    {
        name: 'roomEnvMap',
        type: "texture",
        path: "https://bbcdn.githack.com/niplusplus/showroom/raw/master/static/models/Room/metal-envmap.png?min=1"
    },
    {
        name: 'shelveColorRedMap',
        type: "texture",
        path: "https://bbcdn.githack.com/niplusplus/showroom-final/raw/master/static/models/Room/shelve entry Base Color RED.jpg?min=1"
    },
    {
        name: 'shelveColorNormalMap',
        type: "texture",
        path: "https://bbcdn.githack.com/niplusplus/showroom-final/raw/master/static/models/Room/shelve entry Base Color.jpg?min=1"
    },
    /*{
        name: 'island1Model',
        type: 'gltfModel',
        path: 'models/Island/Island1.glb'
    },
    {
        name: 'island2Model',
        type: 'gltfModel',
        path: 'models/Island/Island2.glb'
    },
    {
        name: 'island3Model',
        type: 'gltfModel',
        path: 'models/Island/Island3.glb'
    },
    {
        name: 'island4Model',
        type: 'gltfModel',
        path: 'models/Island/Island4.glb'
    },
    {
        name: 'island5Model',
        type: 'gltfModel',
        path: 'models/Island/Island5.glb'
    },*/
    {
        name: 'komoModel',
        type: 'gltfModel',
        path: 'https://bbcdn.githack.com/niplusplus/showroom/raw/master/static/models/Komo/maliobojenizmaj.glb?min=1'
    },
    {
        name: 'userModel',
        type: 'gltfModel',
        path: 'https://bbcdn.githack.com/niplusplus/showroom/raw/master/static/models/User/user4.glb?min=1'
    },
    /*{
        name: 'crystalTexture',
        type: 'texture',
        path: 'textures/sphere/crystals.jpg',
    },
    {
        name: 'glowTexture',
        type: 'texture',
        path: 'textures/glow/glow.png',
    }
    {
        name: 'portalModel',
        type: 'gltfModel',
        path: 'models/Portal/Portal.glb'
    },*/
]