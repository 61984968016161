import Experience from "../Experience";
import EventEmitter from "./EventEmitter.js";
import * as THREE from 'three';
export default class Raycast extends EventEmitter{
    constructor(){
        super();
        this.experience = new Experience();
        this.raycaster = new THREE.Raycaster();
        this.pointer = new THREE.Vector2();
    }

    clickHandler(event){
        this.pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
        this.pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
        this.raycaster.setFromCamera(this.pointer, this.experience.camera.instance);
        const intersects = this.raycaster.intersectObjects( this.experience.scene.children, true );
        let names = [];
        for ( let i = 0; i < intersects.length; i ++ ) {
            
            names.push(intersects[i].object.name);
            names.push(intersects[i].object.parent.name);
        }
        if(intersects.length > 0){
            let args = [];
            args.push(names);
            this.trigger("mousemove", args);
            
        }     
    }
}