import TWEEN from '@tweenjs/tween.js';
import * as THREE from'three';
import Experience from "../Experience.js";
import User from './User.js';

export default class Miner{
    constructor(){
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.time = this.experience.time;
        //this.debug = this.experience.debug;
        /*if(this.debug.active){
            this.debugFolder = this.debug.ui.addFolder('miner');
        }*/
        this.resource = this.resources.items.komoModel;

        this.setModel();
        this.setAnimation();
    }

    setModel(){
        this.model = this.resource.scene;
        this.model.visible = false;
        this.model.scale.set(3,3,3);
        this.model.position.set(0.4,1,-0.1);
        this.model.children[0].rotation.x = 0;
        //this.model.rotation.y += Math.PI;
        this.experience.world.user.model.add(this.model);
        this.model.traverse((child) =>{
            if(child.isMesh){
                child.castShadow = true;
            }
        })
    }

    setAnimation(){
        this.animation = {};
        this.animation.mixer = new THREE.AnimationMixer(this.model); 
        
        this.animation.actions = {};
        this.animation.actions.idle = this.animation.mixer.clipAction(this.resource.animations[0]);
        this.animation.actions.walking = this.animation.mixer.clipAction(this.resource.animations[0]);
        this.animation.actions.walkingBackwards = this.animation.mixer.clipAction(this.resource.animations[0]);
        this.animation.actions.digging = this.animation.mixer.clipAction(this.resource.animations[0]);

        this.animation.actions.current = this.animation.actions.idle;
        this.animation.actions.current.play();

        this.animation.play = (name) => {
            const newAction = this.animation.actions[name];
            const oldAction = this.animation.actions.current;
            if(newAction !== oldAction){
                newAction.reset();
                newAction.play();
                newAction.crossFadeFrom(oldAction, 1);
            }

            this.animation.actions.current = newAction;
        }
       /* if(this.debug.active){
            const debugObject = {
                playIdle: () => { this.animation.play('idle') },
                playWalking: () => { this.animation.play('walking'); this.user.animation.play('walking'); },
                playWalkingBackwards: () => { this.animation.play('walkingBackwards') },
                playDigging: () => { this.animation.play('digging') }
            }
            this.debugFolder.add(debugObject, 'playIdle');
            this.debugFolder.add(debugObject, 'playWalking');
            this.debugFolder.add(debugObject, 'playWalkingBackwards');
            this.debugFolder.add(debugObject, 'playDigging');
        }*/
    }

    update(){
        TWEEN.update();
        this.user?.update();
        this.animation.mixer.update(this.time.delta * 0.001);
    }
}