import TWEEN from '@tweenjs/tween.js';
import * as THREE from "three"
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import Experience from "./Experience.js";

export default class Camera{
    constructor(){
        this.experience = new Experience();

        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene;
        this.canvas = this.experience.canvas;
        this.setInstance();
        this.setOrbitControls();
    }

    setInstance(){
        this.instance = new THREE.PerspectiveCamera(75, this.sizes.width / this.sizes.height, 0.1, 1000);
        this.instance.position.set(0, 50, 96);
        /*window.addEventListener("click", ()=>{
            console.log(this.instance);
        },false)*/
        this.scene.add(this.instance);
    }
    setOrbitControls(){
        this.controls = new OrbitControls(this.instance, this.canvas);
        this.controls.target.set(0,6,6.946570862705934)
        this.controls.enablePan = false;
        this.controls.enableDamping = true;
    }
    tweenUp(){
        let initialState = new THREE.Vector3(this.instance.position.x, this.instance.position.y, this.instance.position.z);
        let finalState = new THREE.Vector3(this.instance.position.x, this.instance.position.y+150, this.instance.position.z+150);
        new TWEEN.Tween(initialState)
                .to(finalState)
                .onUpdate(()=>{
                    this.instance.position.set(initialState.x, initialState.y, initialState.z);
                })
                .start();

    }
    tweenDown(){
        let initialState = new THREE.Vector3(this.instance.position.x, this.instance.position.y, this.instance.position.z);
        let finalState = new THREE.Vector3(this.experience.world.user.model.position.x + 0, this.experience.world.user.model.position.y + 1.65, this.experience.world.user.model.position.z+2.2);
        new TWEEN.Tween(initialState)
                .to(finalState)
                .onUpdate(()=>{
                    this.instance.position.set(initialState.x, initialState.y, initialState.z);
                    this.experience.world.ClickContorols.updateCameraTarget(0,0,0);
                })
                .start();

    }
    resize(){
        this.instance.aspect = this.sizes.width / this.sizes.height;
        this.instance.updateProjectionMatrix();
    }
    update(){
        
        TWEEN.update();
        this.controls.update();
    }
}