import TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import Experience from "../Experience.js";

export default class Portal{
    constructor(name, position, positionShift){
        this.experience = new Experience();
        this.raycaster = this.experience.raycaster;
        this.scene = this.experience.scene;
        this.time = this.experience.time;
        this.active;
        this.resources = this.experience.resources;
        this.resource = this.resources.items.portalModel;
        this.introAnimationStarted = false;
        this.setModel(name, position, positionShift);
        
        /*this.raycaster.on("click", (names)=>{
            if(this.active){
                if(names.includes(name)){
                    this.openInterface();
                }            
            }
        })*/
    }
    setModel(name, position, positionShift){
        this.model = new THREE.Object3D();
        this.model.copy(this.resource.scene);
        this.model.name = name;
        if(name == "PortalIsland1"){
            this.active = true;
        }else{
            this.active = false;
        }
        this.model.scale.set(0.6,0.6,0.6);
        this.model.position.set(position.x+positionShift.x, position.y+positionShift.y, position.z+positionShift.z);
        //this.model.rotation.set(0, -Math.PI/6, 0);
        this.model.traverse((child)=>{
            if(child.isMesh && child.name != "plane"){
                this.experience.world.ClickContorols.calculateCollisionPoints(child, 0.6, 'collision', true, child.name);
            }
        })
        this.scene.add(this.model);  
    }
    openInterface(){
        document.getElementById("Teleport-UI").style.display = "flex";
    }
    clickHandler(){
        if(this.active){
            this.openInterface();
        }
    }
    destroy(){
        this.scene.remove(this.model);
    }
    update(){
        TWEEN.update();
    }
}