import * as THREE from 'three';
import { SkinnedMesh } from 'three';
import Experience from "../Experience.js";
export default class ClickControls{
    constructor(){
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.renderer = this.experience.renderer.instance;
        this.camera = this.experience.camera.instance;
        this.objects = [];
        this.collisions = [];
        this.movements = [];
        this.playerSpeed = 0.2;
        this.raycaster = new THREE.Raycaster();
        this.mouse = new THREE.Vector2();        
        this.cameraTarget = new THREE.Vector3();
        this.orbitControlls = this.experience.camera.controls;

        this.rotationPoint = this.experience.world.user.model;
        const root = this.rotationPoint;
        let bBox = new THREE.Box3().setFromObject(root);
        let boxCenter = bBox.getCenter(new THREE.Vector3());
        let boxSize = bBox.getSize(new THREE.Vector3()).length();
        let geo = new THREE.BoxGeometry(boxSize, boxSize, boxSize);
        let mat = new THREE.MeshStandardMaterial({transparent: true, opacity: 1, color: 0xffffff});
        this.box = new THREE.Mesh(geo, mat);
        this.box.position.set(this.rotationPoint.position.x, this.rotationPoint.position.y, this.rotationPoint.position.z);
        //this.scene.add(this.box);
        /*this.boxHelper = new THREE.BoxHelper( this.rotationPoint, 0xffff00 );
        this.scene.add( this.boxHelper );*/
        this.indicatorTop;
        this.indicatorBottom;
        document.addEventListener("touchstart", (event)=>{
          this.onDocumentMouseDown(event, false)
        })
        document.addEventListener( "contextmenu", (event)=>{
            this.onDocumentMouseDown(event, false)
        }, false );
    }
    updateCameraTarget(){

      this.cameraTarget.x = this.experience.world.user.model.position.x;
      this.cameraTarget.y = this.experience.world.user.model.position.y;
      this.cameraTarget.z = this.experience.world.user.model.position.z;

      this.orbitControlls.target = this.cameraTarget;
    }
    move( location, destination, speed = this.playerSpeed ) {
        var moveDistance = speed;
        // Translate over to the position.
        var posX = location.position.x;
        var posZ = location.position.z;
        var newPosX = destination.x;
        var newPosZ = destination.z;
    
        // Set a multiplier just in case we need negative values.
        var multiplierX = 1;
        var multiplierZ = 1;
        
        // Detect the distance between the current pos and target.
        var diffX = Math.abs( posX - newPosX );
        var diffZ = Math.abs( posZ - newPosZ );
        var distance = Math.sqrt( diffX * diffX + diffZ * diffZ );
        
        // Use negative multipliers if necessary.
        if (posX > newPosX) {
            multiplierX = -1;
        }
    
        if (posZ > newPosZ) {
            multiplierZ = -1;
        }
    
        // Update the main position.
        location.position.x = location.position.x + ( moveDistance * ( diffX / distance )) * multiplierX;
        location.position.z = location.position.z + ( moveDistance * ( diffZ / distance )) * multiplierZ;
        this.rotationPoint.lookAt(destination.x,this.rotationPoint.position.y, destination.z);
        this.camera.position.x += ( moveDistance * ( diffX / distance )) * multiplierX;
        this.camera.position.z += ( moveDistance * ( diffZ / distance )) * multiplierZ;
        this.cameraTarget.x = this.experience.world.user.model.position.x;
        this.cameraTarget.y = this.experience.world.user.model.position.y+4.7;
        this.cameraTarget.z = this.experience.world.user.model.position.z;
        this.orbitControlls.target = this.cameraTarget;
        /*this.scene.remove(this.boxHelper)
        this.boxHelper = new THREE.BoxHelper( this.rotationPoint, 0xffff00 );
        this.scene.add( this.boxHelper );*/
        
        this.experience.world.user.animation.play("walking");
        this.experience.world.miner.model.children[0].rotation.x = Math.PI/2;
        this.experience.world.miner.animation.play("walking");
        this.box.position.set(this.rotationPoint.position.x, this.rotationPoint.position.y, this.rotationPoint.position.z);
        this.box.rotation.set(this.rotationPoint.rotation.x, this.rotationPoint.rotation.y, this.rotationPoint.rotation.z);
        // If the position is close we can call the movement complete.
        if (( Math.floor( location.position.x ) <= Math.floor( newPosX ) + 0.00001 && 
                Math.floor( location.position.x ) >= Math.floor( newPosX ) - 0.00001 ) &&
            ( Math.floor( location.position.z ) <= Math.floor( newPosZ ) + 0.00001 && 
                Math.floor( location.position.z ) >= Math.floor( newPosZ ) - 0.00001 )) {
            /*location.position.x = Math.floor( location.position.x );
            location.position.z = Math.floor( location.position.z );*/
            // Reset any movements.
            this.stopMovement(true);
            
            // Maybe move should return a boolean. True if completed, false if not. 
        }
    }
  
    /**
     * Stop character movement.
     */
    stopMovement(toIdle) {
        this.movements = [];
        if(toIdle){
            this.experience.world.user.animation.play("idle");
            this.experience.world.miner.model.children[0].rotation.x = 0;
            this.experience.world.miner.animation.play("idle");
        }
        this.scene.remove( this.indicatorTop );
        this.scene.remove( this.indicatorBottom );
    }
    onDocumentMouseDown( event) {
        event.preventDefault();
        // Detect which mouse button was clicked.
        //if ( event.which == 3 || bypass === true ) {
        this.stopMovement(false);
        
        // Grab the coordinates.
        if(event.clientX){
          this.mouse.x = ( event.clientX / this.renderer.domElement.clientWidth ) * 2 - 1;
          this.mouse.y = - ( event.clientY / this.renderer.domElement.clientHeight ) * 2 + 1;
        }else{
          this.mouse.x = ( event.changedTouches[0].clientX / this.renderer.domElement.clientWidth ) * 2 - 1;
          this.mouse.y = - ( event.changedTouches[0].clientY / this.renderer.domElement.clientHeight ) * 2 + 1;
        }
        // Use the raycaster to detect intersections.
        this.raycaster.setFromCamera( this.mouse, this.camera );
        // Grab all objects that can be intersected.
        var intersects = this.raycaster.intersectObjects( this.objects, true );
        if ( intersects.length > 0 ) {
            this.movements.push(intersects[ 0 ].point);
        }else{
          this.stopMovement(true);
        }
    }

    drawIndicator() {
        // Store variables.
        var characterSize = 1;
        var outlineSize = characterSize * 0.05;
        var topSize = characterSize/8;
        var bottomRadius = characterSize/4;
        
        // Create the top indicator.
        var geometry = new THREE.TetrahedronGeometry( topSize, 0 );
        var material = new THREE.MeshToonMaterial({ color: 0x00ccff, emissive: 0x00ccff  });
        this.indicatorTop = new THREE.Mesh( geometry, material );
        this.indicatorTop.position.y = 3.1; // Flat surface so hardcode Y position for now.
        this.indicatorTop.position.x = this.movements[ 0 ].x; // Get the X destination.
        this.indicatorTop.position.z = this.movements[ 0 ].z; // Get the Z destination.
        this.indicatorTop.rotation.x = -0.97;
        this.indicatorTop.rotation.y = Math.PI/4;
        this.indicatorTop.name = 'indicator_top'
        this.scene.add( this.indicatorTop );
        
        // Create the top indicator outline.
        var geometry = new THREE.TetrahedronGeometry( topSize + outlineSize, 0 );
        var material = new THREE.MeshBasicMaterial({ color : 0x0000000, side: THREE.BackSide });
        var outlineTop = new THREE.Mesh( geometry, material );
        this.indicatorTop.add( outlineTop );
        
        // Create the bottom indicator.
        var geometry = new THREE.TorusGeometry( bottomRadius, ( bottomRadius * 0.25), 2, 12 );
        geometry.dynamic = true;
        var material = new THREE.MeshToonMaterial({ color: 0x00ccff, emissive: 0x00ccff });
        this.indicatorBottom = new THREE.Mesh( geometry, material );
        this.indicatorBottom.position.y = 2.5;
        this.indicatorBottom.position.x = this.movements[ 0 ].x;
        this.indicatorBottom.position.z = this.movements[ 0 ].z;
        this.indicatorBottom.rotation.x = -Math.PI/2;
        this.scene.add( this.indicatorBottom );
        
        // Create the bottom outline.
        var geometry = new THREE.TorusGeometry( bottomRadius + outlineSize/10, bottomRadius / 2.5, 2, 24 );
        var material = new THREE.MeshBasicMaterial({ color : 0x0000000, side: THREE.BackSide });
        var outlineBottom = new THREE.Mesh( geometry, material );
        outlineBottom.position.z = -2;
        this.indicatorBottom.add( outlineBottom );
    }
    detectCollisions() {
      // Get the user's current collision area.
      var bounds = {
        xMin: this.rotationPoint.position.x - this.box.geometry.parameters.width / 2,
        xMax: this.rotationPoint.position.x + this.box.geometry.parameters.width / 2,
        yMin: this.rotationPoint.position.y - this.box.geometry.parameters.height / 2,
        yMax: this.rotationPoint.position.y + this.box.geometry.parameters.height / 2,
        zMin: this.rotationPoint.position.z - this.box.geometry.parameters.width / 2,
        zMax: this.rotationPoint.position.z + this.box.geometry.parameters.width / 2,
      };
      
      // Run through each object and detect if there is a collision.
      for ( var index = 0; index < this.collisions.length; index ++ ) {
    
        if (this.collisions[ index ].type == 'collision' ) {
          if ( ( bounds.xMin <= this.collisions[ index ].xMax && bounds.xMax >= this.collisions[ index ].xMin ) &&
              ( bounds.yMin <= this.collisions[ index ].yMax && bounds.yMax >= this.collisions[ index ].yMin) &&
              ( bounds.zMin <= this.collisions[ index ].zMax && bounds.zMax >= this.collisions[ index ].zMin) ) {
            // We hit a solid object! Stop all movements.
            /*if(this.collisions[ index ].isportal){
              console.log(this.collisions[ index ].name);
              this.openUI();
            }else{
              console.log(this.collisions[ index ].name);
            }*/
            this.stopMovement(true);
            
    
            // Move the object in the clear. Detect the best direction to move.
            if ( bounds.xMin <= this.collisions[ index ].xMax && bounds.xMax >= this.collisions[ index ].xMin ) {
              // Determine center then push out accordingly.
              var objectCenterX = ((this.collisions[ index ].xMax - this.collisions[ index ].xMin) / 2) + this.collisions[ index ].xMin;
              var playerCenterX = ((bounds.xMax - bounds.xMin) / 2) + bounds.xMin;
              var objectCenterZ = ((this.collisions[ index ].zMax - this.collisions[ index ].zMin) / 2) + this.collisions[ index ].zMin;
              var playerCenterZ = ((bounds.zMax - bounds.zMin) / 2) + bounds.zMin;
    
              // Determine the X axis push.
              if (objectCenterX > playerCenterX) {
                this.rotationPoint.position.x -=0.01;
              } else {
                this.rotationPoint.position.x +=0.01;
              }
            }
            if ( bounds.zMin <= this.collisions[ index ].zMax && bounds.zMax >= this.collisions[ index ].zMin ) {
              // Determine the Z axis push.
              if (objectCenterZ > playerCenterZ) {
              this.rotationPoint.position.z -=0.01;
              } else {
                this.rotationPoint.position.z +=0.01;
              }
            }
          }
        }
      }
    }
    calculateCollisionPoints( mesh, scale, type = 'collision', isportal, name ) { 
        // Compute the bounding box after scale, translation, etc.
        if(["Cube058","Cube019","Cube006","Cube026","Cube009", "Cube035", "Cube032", "Cube038"].indexOf(mesh.parent.name)>-1){
          mesh = mesh.parent;
        }
        var bbox = new THREE.Box3().setFromObject(mesh);
        let vec = new THREE.Vector3();
        mesh.getWorldPosition(vec);

        var bounds = {
        name: mesh.name,
        isportal: isportal,
        type: type,
        xMin: bbox.min.x,
        xMax: bbox.max.x,
        yMin: bbox.min.y,
        yMax: bbox.max.y,
        zMin: bbox.min.z,
        zMax: bbox.max.z,
        };
        /*const box = new THREE.BoxHelper( bbox, 0xffff00 );
        this.scene.add( box );*/
        /*const helper = new THREE.Box3Helper( bbox, 0xffff00 );
        this.scene.add( helper );*/
        this.collisions.push( bounds );
    }
    openUI(){
      if(this.experience.world.floor1.portal.active){
        this.experience.world.floor1.portal.openInterface();
      }
      if(this.experience.world.floor2.portal.active){
        this.experience.world.floor2.portal.openInterface();
      }
      if(this.experience.world.floor3.portal.active){
        this.experience.world.floor3.portal.openInterface();
      }
      if(this.experience.world.floor4.portal.active){
        this.experience.world.floor4.portal.openInterface();
      }
      if(this.experience.world.floor5.portal.active){
        this.experience.world.floor5.portal.openInterface();
      }
    }
    update(){
        if ( this.movements.length > 0 ) {
          // Set an indicator point to destination.
          /*if ( this.scene.getObjectByName('indicator_top') === undefined ) {
              this.drawIndicator();
          } else {
              if ( this.indicatorTop.position.y > 10 ) {
              this.indicatorTop.position.y -= 3;
              } else {
              this.indicatorTop.position.y = 1.1;
              }
          }*/
          
              this.move( this.rotationPoint, this.movements[ 0 ] );
          }
          if ( this.collisions.length > 0 ) {
            this.detectCollisions();
          }
    }
}