import TWEEN from '@tweenjs/tween.js';
import * as THREE from'three';
import Experience from "../Experience.js";

export default class User{
    constructor(){
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.time = this.experience.time;
        //this.debug = this.experience.debug;
        /*if(this.debug.active){
            this.debugFolder = this.debug.ui.addFolder('User');
        }*/
        this.resource = this.resources.items.userModel;

        this.setModel();
        this.setAnimation();
    }

    setModel(){
        this.model = this.resource.scene;
        this.model.visible = false;
        this.model.scale.set(0.6,0.6,0.6);
        this.model.position.set(0,1.3,7);
        this.scene.add(this.model);
        this.model.traverse((child) =>{
            if(child.isMesh){
                child.castShadow = true;
            }
        })
    }

    setAnimation(){
        this.animation = {};
        this.animation.mixer = new THREE.AnimationMixer(this.model);
        this.animation.actions = {};
        this.animation.actions.idle = this.animation.mixer.clipAction(this.resource.animations[0]);
        this.animation.actions.walking = this.animation.mixer.clipAction(this.resource.animations[1]);
        this.animation.actions.walkingBackwards = this.animation.mixer.clipAction(this.resource.animations[1]);
        this.animation.actions.digging = this.animation.mixer.clipAction(this.resource.animations[1]);

        this.animation.actions.current = this.animation.actions.idle;
        this.animation.actions.current.play();

        this.animation.play = (name) => {
            const newAction = this.animation.actions[name];
            const oldAction = this.animation.actions.current;
            if(newAction !== oldAction){
                this.experience.world.miner.animation.play(name);
                newAction.reset();
                newAction.play();
                newAction.crossFadeFrom(oldAction, 0.4);
            }

            this.animation.actions.current = newAction;
            if(name == "idle"){
                this.animation.actions.current.timeScale = 4;
            }else{
                this.animation.actions.current.timeScale = 1;
            }
        }
        /*if(this.debug.active){
            const debugObject = {
                playIdle: () => { this.animation.play('idle') },
                playWalking: () => { this.animation.play('walking') },
                playWalkingBackwards: () => { this.animation.play('walkingBackwards') },
                playDigging: () => { this.animation.play('digging') }
            }
            this.debugFolder.add(debugObject, 'playIdle');
            this.debugFolder.add(debugObject, 'playWalking');
            this.debugFolder.add(debugObject, 'playWalkingBackwards');
            this.debugFolder.add(debugObject, 'playDigging');
        }*/
    }
    teleport(position){
        let initialPosition = new THREE.Vector3(this.model.position.x, this.model.position.y, this.model.position.z);
        new TWEEN.Tween(initialPosition)
                .to(position)
                .onUpdate(()=>{
                    this.experience.world.minerControls.updateCameraTarget(this.model.position.x, this.model.position.z)
                    this.model.position.x = initialPosition.x;
                    this.model.position.z = initialPosition.z;
                })
                .onComplete(()=>{
                    setTimeout(()=>{
                        this.experience.camera.tweenDown();
                    }, 500)
                })
                .start();

    }
    update(){
        TWEEN.update();
        this.animation.mixer.update(this.time.delta * 0.001);
    }
}