import * as THREE from 'three';
import Experience from "../Experience.js";
import Environment from './Environment.js';
import Floor from './Floor.js';
import Miner from './Miner.js';
import MinerControls from './MinerControls.js';
import User from './User.js';
import CollisionDetection from '../Utils/CollisionDetection.js';
import ClickControls from '../Utils/ClickControls.js';

export default class World{
    constructor(){
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.resources.on('ready', ()=>{
            document.getElementById("loading-screen").style.display = "none";
            this.envronment = new Environment();
                        
            this.user = new User();
            this.miner = new Miner();
            this.ClickContorols = new ClickControls();
            this.floor1 = new Floor("Room", new THREE.Vector3(0
            ,1,
            0), 
            this.resources.items.roomModel);
            /*this.floor2 = new Floor("Island2", new THREE.Vector3((Math.random()*2 - 1) * 400
            ,1,
            (-Math.random()) * 550), 
            this.resources.items.island2Model);
            this.floor3 = new Floor("Island3", new THREE.Vector3((Math.random()*2 - 1) * 600
            ,1,
            (-Math.random()) * 430), 
            this.resources.items.island3Model);
            this.floor4 = new Floor("Island4", new THREE.Vector3((Math.random()*2 - 1) * 480
            ,1,
            (-Math.random()) * 590), 
            this.resources.items.island4Model);
            this.floor5 = new Floor("Island5", new THREE.Vector3((Math.random()*2 - 1) * 620
            ,1,
            (-Math.random()) * 430), 
            this.resources.items.island5Model);*/
        })
    }
    setFloorsToInactive(){
        this.floor1.portal.active = false;
        document.getElementById("Island1").className = "item"
        this.floor2.portal.active = false;
        document.getElementById("Island2").className = "item"
        this.floor3.portal.active = false;
        document.getElementById("Island3").className = "item"
        this.floor4.portal.active = false;
        document.getElementById("Island4").className = "item"
        this.floor5.portal.active = false;
        document.getElementById("Island5").className = "item"
    }
    teleportToIsland(position){
        this.experience.camera.tweenUp();
        this.user.teleport(new THREE.Vector3(position.x, position.y, position.z+7));
    }
    attachControls(){
        //this.minerControls = new MinerControls();
    }

    update(){
        this.floor1?.update();
        this.floor2?.update();
        this.floor3?.update();
        this.floor4?.update();
        this.floor5?.update();
        //this.miner?.update();
        this.user?.update();
        this.ClickContorols?.update();
        //this.minerControls?.update(this.experience.time?.delta);
    }
}