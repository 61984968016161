import * as THREE from 'three';
import Experience from "../Experience.js";
export default class Environment{
    constructor(){
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        //this.debug = this.experience.debug;

        /*if(this.debug.active){
            this.debugFolder = this.debug.ui.addFolder("enviornment");
        }*/

        let light = new THREE.AmbientLight( 0xffffff ); // soft white light
        //this.scene.add( light );
        this.setSunLight();
        //this.setEnvironmentMap();
    }
    setSunLight(){
        this.sunLight = new THREE.DirectionalLight('#ffffff', 1);
        this.sunLight.castShadow = true;
        this.sunLight.shadow.camera.far = 15;
        this.sunLight.shadow.mapSize.set(1024,1024);
        this.sunLight.shadow.normalBias = 0.05;
        this.sunLight.position.set(5,10,7.5);
        //this.scene.add(this.sunLight);

        /*if(this.debug.active){
            this.debugFolder
                .add(this.sunLight, 'intensity')
                .name('sunLightIntensity')
                .min(0)
                .max(10)
                .step(0.001);
            this.debugFolder
                .add(this.sunLight.position, 'x')
                .name('sunLightX')
                .min(-5)
                .max(5)
                .step(0.001);
            this.debugFolder
                .add(this.sunLight.position, 'y')
                .name('sunLightY')
                .min(-5)
                .max(5)
                .step(0.001);
            this.debugFolder
                .add(this.sunLight.position, 'z')
                .name('sunLightZ')
                .min(-5)
                .max(5)
                .step(0.001);
        }*/

        this.sunLight1 = new THREE.DirectionalLight('#ffffff', 1);
        this.sunLight1.castShadow = true;
        this.sunLight1.shadow.camera.far = 15;
        this.sunLight1.shadow.mapSize.set(1024,1024);
        this.sunLight1.shadow.normalBias = 0.05;
        this.sunLight1.position.set(-5,2.765,-5);
       // this.scene.add(this.sunLight1);

        /*if(this.debug.active){
            this.debugFolder
                .add(this.sunLight1, 'intensity')
                .name('sunLight1Intensity')
                .min(0)
                .max(10)
                .step(0.001);
            this.debugFolder
                .add(this.sunLight1.position, 'x')
                .name('sunLight1X')
                .min(-5)
                .max(5)
                .step(0.001);
            this.debugFolder
                .add(this.sunLight1.position, 'y')
                .name('sunLight1Y')
                .min(-5)
                .max(5)
                .step(0.001);
            this.debugFolder
                .add(this.sunLight1.position, 'z')
                .name('sunLight1Z')
                .min(-5)
                .max(5)
                .step(0.001);
        }*/
    }

    setEnvironmentMap(){
        this.environmentMap = {};
        this.environmentMap.intensity = 0.4;
        this.environmentMap.texture = this.resources.items.environmentMapTexture;
        this.environmentMap.texture.encoding = THREE.sRGBEncoding;
        //this.scene.background = this.environmentMap.texture;
        this.scene.environment = this.environmentMap.texture;

        this.environmentMap.updateMaterial = ()=>{
            this.scene.traverse((child)=>{
                if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial){
                    child.material.envMap = this.environmentMap.texture;
                    child.material.envMapIntensity = this.environmentMap.intensity;
                    child.material.needsUpdate = true;
                }
            })
        }
        this.environmentMap.updateMaterial();

        /*if(this.debug.active){
            this.debugFolder
                .add(this.environmentMap, 'intensity')
                .name('envMapIntensity')
                .min(0)
                .max(12)
                .step(0.001)
                .onChange( this.environmentMap.updateMaterial )
        }*/
    }
}