import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import EventEmitter from "./EventEmitter.js";

export default class Resources extends EventEmitter{
    constructor(sources){
        super();

        this.sources = sources;
        this.items = {};
        this.toLoad = this.sources.length;
        this.loaded = 0;
        this.setLoaders();
        this.startLoading();
    }

    setLoaders(){
        this.loaders = {};
        /*this.loaders.draco = new DRACOLoader();
        this.loaders.draco.setDecoderPath("https://cdn.jsdelivr.net/npm/three@0.124.0/examples/js/libs/draco/")*/
        this.loaders.gltfLoader = new GLTFLoader();
        //this.loaders.gltfLoader.setDRACOLoader( this.loaders.draco );
        this.loaders.textureLoader = new THREE.TextureLoader();
        this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader();
    }

    startLoading(){
        for(const source of this.sources){
            if(source.type === "gltfModel"){

                this.loaders.gltfLoader.load(
                    source.path,
                    (file) => {
                        this.sourceLoaded(source,file);
                    },
                    ( xhr ) => {
                        if(source.path == "https://bbcdn.githack.com/niplusplus/showroom-final/raw/master/static/models/Room/output.glb?min=1"){
                            document.querySelector(".counter h1").innerHTML = parseInt( xhr.loaded / xhr.total * 100 ) + "%";
                            document.querySelector(".counter hr").style.width = parseInt( xhr.loaded / xhr.total * 100 ) + "%";
                        }
                
                    },
                    // called when loading has errors
                    ( error ) => {
                
                        console.log( 'An error happened' );
                
                    }
                );

            }else if(source.type === "texture"){
                this.loaders.textureLoader.load(
                    source.path,
                    (file) => {
                        this.sourceLoaded(source,file)
                    }
                );
            }else if(source.type === "cubeTexture"){
                this.loaders.cubeTextureLoader.load(
                    source.path,
                    (file) => {
                        this.sourceLoaded(source,file)
                    }
                );
            }
        }
    }

    sourceLoaded(source, file){
        this.items[source.name] = file;
        this.loaded++;

        if(this.loaded == this.toLoad){
            this.trigger("ready");
        }
    }
}