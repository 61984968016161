import './style.css'
import Experience from './Experience/Experience.js';
let lmbActive = true;
let rmbActive = false;
document.getElementById("LMB").addEventListener("contextmenu", (e)=>{
    e.preventDefault();
    e.stopImmediatePropagation();
},false);
let startX = 0;
let startY = 0;
document.body.addEventListener("pointerdown", (e)=>{
    startX = e.clientX;
    startY = e.clientY;
},false);
document.body.addEventListener("pointerup", (e)=>{
    if(Math.abs(e.clientX - startX) > 50){
        if(lmbActive){
            lmbActive = false;
            document.getElementById("LMB").style.display = "none";
            document.getElementById("RMB").style.display = "";
            rmbActive = true;
        }
    }
});
document.body.addEventListener("contextmenu", ()=>{
    if(rmbActive){
        rmbActive = false;
        document.getElementById("RMB").style.display = "none";
        document.getElementsByClassName("tutorial")[0].style.display = "none";
        //document.body.style.touchAction = "";
    }
},false);
const experience = new Experience(document.querySelector("canvas.webgl"));

document.getElementById("parent").addEventListener("click", function(event) {
    if (event.target === this) {
      // Parent was clicked, do something
      this.style.display = "none";
    }
  });

document.getElementById("close-UI").addEventListener("click", ()=>{
    document.getElementById("Teleport-UI").style.display = "none";
});
document.getElementById("Island1").addEventListener("click", ()=>{
    experience.world.setFloorsToInactive();
    document.getElementById("Teleport-UI").style.display = "none";
    experience.world.floor1.portal.active = true;
    document.getElementById("Island1").className = "item active"
    experience.world.teleportToIsland(experience.world.floor1.model.position);
})
document.getElementById("Island2").addEventListener("click", ()=>{
    experience.world.setFloorsToInactive();
    document.getElementById("Teleport-UI").style.display = "none";
    experience.world.floor2.portal.active = true;
    document.getElementById("Island2").className = "item active"
    experience.world.teleportToIsland(experience.world.floor2.model.position);
})
document.getElementById("Island3").addEventListener("click", ()=>{
    experience.world.setFloorsToInactive();
    document.getElementById("Teleport-UI").style.display = "none";
    experience.world.floor3.portal.active = true;
    document.getElementById("Island3").className = "item active"
    experience.world.teleportToIsland(experience.world.floor3.model.position);
})
document.getElementById("Island4").addEventListener("click", ()=>{
    experience.world.setFloorsToInactive();
    document.getElementById("Teleport-UI").style.display = "none";
    experience.world.floor4.portal.active = true;
    document.getElementById("Island4").className = "item active"
    experience.world.teleportToIsland(experience.world.floor4.model.position);
})
document.getElementById("Island5").addEventListener("click", ()=>{
    experience.world.setFloorsToInactive();
    document.getElementById("Teleport-UI").style.display = "none";
    experience.world.floor5.portal.active = true;
    document.getElementById("Island5").className = "item active"
    experience.world.teleportToIsland(experience.world.floor5.model.position);
})
